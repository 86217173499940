import { ref } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";

export type MainStoreData = Array<unknown>;
export interface MainStoreMeta extends Record<string, unknown> { }

interface IMainStore {
    data: MainStoreData;
    meta: MainStoreMeta;
}

export const useMainStore = defineStore("main", () => {

    const records = ref<any>({
        data: [],
        total: 0
    });

    const isDataLoading = ref(false);
    const isModalOpen = ref(false);
    const isModalOpenTwo = ref(false);
    const isModalOpenThree = ref(false);
    const isSidebarOpen  = ref(false);
    const basicInfo = ref<any>(null);

    const userMenus = ref([]);

    const setRecords = (jsonData: IMainStore) => {
        records.value.data = jsonData.data;
        records.value.total = jsonData.meta ? jsonData.meta.total : 0;
    }

    const pushRecords = (jsonData: IMainStore) => {
        records.value.data = records.value.data.concat(jsonData.data);
        records.value.total = jsonData.meta ? jsonData.meta.total : 0;
    }

    const resetRecords = () => {
        records.value.data = [];
        records.value.total = 0;
    }

    const addRecord = (record: object) => {
        records.value.data.unshift(record);
        records.value.total += 1;
    }

    const updateRecord = (record: any) => {
        const index = records.value.data.findIndex((item: any) => item.id == record.id);
        if(index < 0) return;

        records.value.data.splice(index, 1, record);
    }

    const getInitialInfo = async () => {
      ApiService.setHeader();
      await ApiService.get("profile/basic-info")
        .then(({ data }) => {
          basicInfo.value = data.json_data;
        });
    }

    return {
        basicInfo,
        records, 
        isDataLoading, 
        isModalOpen,
        isModalOpenTwo,
        isModalOpenThree,
        setRecords,
        pushRecords,
        resetRecords,
        addRecord,
        updateRecord,
        userMenus, 
        isSidebarOpen,
        getInitialInfo
    };

});
