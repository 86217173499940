<template>
  <RouterView/>
  <LoadingBar/>
</template>

<script setup lang="ts">
import { nextTick, onBeforeMount, onMounted } from "vue";
import { RouterView } from "vue-router";
import { useConfigStore } from "@/stores/config";
import { useThemeStore } from "@/stores/theme";
import { useBodyStore } from "@/stores/body";
import { useMainStore } from "@/stores"
import { themeConfigValue } from "@/layouts/default-layout/config/helper";
import { initializeComponents } from "@/core/plugins/keenthemes";
import LoadingBar from "@/components/global/LoadingBar.vue";

const configStore = useConfigStore();
const themeStore = useThemeStore();
const bodyStore = useBodyStore();
const mainStore = useMainStore();

onBeforeMount(async () => {
  /**
   * Overrides the layout config using saved data from localStorage
   * remove this to use static config (@/layouts/default-layout/config/DefaultLayoutConfig.ts)
   */
  configStore.overrideLayoutConfig();
  /**
   *  Sets a mode from configuration
   */
  themeStore.setThemeMode(themeConfigValue.value);
});

onMounted(async () => {

  nextTick(() => {
    initializeComponents();
    bodyStore.removeBodyClassName("page-loading");
  });

  await mainStore.getInitialInfo();
});
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "animate.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";


// Main demo style scss
@import "assets/keenicons/duotone/style.css";
@import "assets/keenicons/outline/style.css";
@import "assets/keenicons/solid/style.css";
@import "assets/sass/element-ui.dark";
@import "assets/sass/element-ui.light";
@import "assets/sass/plugins";
@import "assets/sass/style";

//@import "ckeditor5/ckeditor5.css";
//@import "ckeditor5-premium-features/ckeditor5-premium-features.css";

#app {
  display: contents;
}
</style>
