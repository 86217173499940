import type { RouteRecordRaw } from "vue-router";

const settingRoutes: Array<RouteRecordRaw> = [
    {
        path: "/settings",
        name: "settings",
        children: [
            {
                path: "/settings/global-postback",
                name: "globalPostback",
                component: () => import("@/views/settings/GlobalPostback.vue"),
                meta: {
                    pageTitle: "Global Postback",
                    breadcrumbs: ["Global Postback"],
                }
            },
            {
                path: "email-settings",
                name: "emailSettings",
                children: [
                    {
                        path: "integration-email",
                        name: "integrationEmail",
                        component: () => import("@/views/settings/email-settings/integration-email/IntegrationEmail.vue"),
                        meta: {
                            pageTitle: "Integration Email",
                            breadcrumbs: ["Email Setting", "Integration Email"],
                        }
                    }
                ]
            }
        ]
    },
];

export default settingRoutes;