import type { RouteRecordRaw } from "vue-router";

const affiliateRoutes: Array<RouteRecordRaw> = [
    {
        path: "/template-offer",
        name: "templateOffer",
        component: () => import("@/views/template-offer/TemplateOfferList.vue"),
        meta: {
            pageTitle: "Creative Offer",
            breadcrumbs: ["Creative Offer"],
        }
    },
    {
        path: "/creative-template",
        name: "creativeTemplate",
        component: () => import("@/views/creative-template/CreativeTemplateList.vue"),
        meta: {
            pageTitle: "Creative Template",
            breadcrumbs: ["Creative Template"],
        }
    },
    {
        path: "/creative",
        name: "creative",
        component: () => import("@/views/creative/CreativeList.vue"),
        meta: {
            pageTitle: "Affiliate Creatives",
            breadcrumbs: ["Affiliate Creatives"],
        }
    }, 
    {
        path: "/case-study",
        name: "caseStudy",
        component: () => import("@/views/case-study/CaseStudyList.vue"),
        meta: {
            pageTitle: "Case Study",
            breadcrumbs: ["Case Study"],
        }
    }, 
    {
        path: "/case-study-category",
        name: "caseStudyCategory",
        component: () => import("@/views/case-study/category/CategoryList.vue"),
        meta: {
            pageTitle: "Category",
            breadcrumbs: ["Case Study", "Categories"],
        }
    },
    {
        path: "/case-study-tags",
        name: "caseStudyTag",
        component: () => import("@/views/case-study/tag/TagList.vue"),
        meta: {
            pageTitle: "Tags",
            breadcrumbs: ["Case Study", "Tags"],
        }
    },
    {
        path: "/conference",
        name: "conference",
        component: () => import("@/views/conference/ConferenceList.vue"),
        meta: {
            pageTitle: "Conferences",
            breadcrumbs: ["Conferences"],
        }
    }, 
    {
        path: "/newest-campaign",
        name: "newestCampaign",
        component: () => import("@/views/newest-campaign/NewestCampaignList.vue"),
        meta: {
            pageTitle: "Campaign News",
            breadcrumbs: ["Campaign News"],
        }
    }, 
    {
        path: "/portal-offers",
        name: "portalOffers",
        component: () => import("@/views/portal-offers/OfferList.vue"),
        meta: {
            pageTitle: "Portal Offers",
            breadcrumbs: ["Portal Offers"],
        }
    },
    {
        path: "/portal-offers/requests",
        name: "offerRequests",
        component: () => import("@/views/portal-offers/OfferRequests.vue"),
        meta: {
            pageTitle: "Offer Requests",
        }
    },
    {
        path: "/general-information",
        name: "generalInformation",
        component: () => import("@/views/general-information/GeneralInformationList.vue"),
        meta: {
            pageTitle: "General Information",
            breadcrumbs: ["General Information"],
        }
    }
];

export default affiliateRoutes;